<template>
  <div class="address-main">
    <pageBread
      :text="pageTitle"
      :img="() => require('../../assets/image/address-pageIcon.png')"
    ></pageBread>
    <br />
    <nqForm
      :submitText="submitText"
      @submit="submitForm"
      @cancel="cancelForm"
      cancelText="取消"
      :values="formValue"
      :formList="[
        {
          label: '目的地',
          key: 'nationId',
          type: 'countrySelect',
          placeholder: '请选择国家',
        },
        {
          label: '城市',
          key: 'city',
          type: 'input',
          placeholder: '请输入城市',
        },
        {
          label: '邮编',
          key: 'post',
          type: 'input',
          placeholder: '请输入邮编',
        },
        {
          label: '收件人',
          key: 'recipient',
          type: 'input',
          placeholder: '请输入详收件人全名，请勿简写',
        },
        {
          label: '联系电话',
          key: 'tel',
          type: 'input',
          placeholder: '请输入联系电话',
        },
        {
          label: '详细地址',
          key: 'detailAddress',
          type: 'input',
          maxlength: '1000000000',
          placeholder: 'P.O,BOX邮箱地址无法派送请填写详细地址(部分中东地区)',
        },
        {
          label: '公司名',
          key: 'company',
          type: 'input',
          placeholder: '如果是私人快件，此处填写收件人姓名',
        },
        {
          label: '电子邮箱',
          key: 'email',
          type: 'input',
          placeholder: '选填，请输入收件人邮箱',
          norequire: true,
        },
        {
          label: '收件人增值税或其他税号',
          key: 'taxNote',
          type: 'input',
          placeholder: '选填',
          norequire: true,
        },
      ]"
    ></nqForm>
  </div>
</template>
<script>
import { addressInfo } from '../../utils/apis/apis'
export default {
  name: 'add-address',
  data() {
    return {
      type: true,
      submitText: '添加',
      pageTitle: '新增地址',
      formValue: {
        nationId: '', // 国家Id
        city: '', // 城市
        post: '', // 邮编
        recipient: '', // 收货人
        tel: '', // 电话号码
        detailAddress: '', // 详细地址
        company: '', // 公司
        email: '', // 电子邮箱，比如 example@qq.com
        taxNote: '', // 收件人增值税或其它税号
        memberId: '' // 会员ID
      }
    }
  },
  created() {
    this.type = this.$route.path === '/address-add'
    // 如果是编辑
    if (!this.type) {
      this.submitText = '编辑'
      this.$reqGet(addressInfo.item + '/' + this.$route.params.id).then(
        (res) => {
          if (res.data.code === 200) {
            this.formValue = res.data.data
          }
        }
      )
    }
  },
  methods: {
    submitForm(data) {
      if (!this.type) {
        data.id = this.$route.params.id
        this.$reqPut(addressInfo.update, data).then((res) => {
          if (res.data.code === 200) {
            this.$toast.success('编辑成功')
            this.goBack()
          }
        })
      } else {
        this.$reqPost(addressInfo.add, data).then((res) => {
          if (res.data.code === 200) {
            this.$toast.success('新增成功')
            this.goBack()
          }
        })
      }
    },
    cancelForm() {
      this.goBack()
    },
    goBack() {
      this.$router.go(-1)
    },
    addData() {}
  }
}
</script>
<style lang="less" scoped>
/deep/.form-item-ele {
  padding-right: 8px !important;
}
</style>
